<template>
  <div id="video">
    <div class="title news-title gn-full-x gn-flex-center">{{ info.title }}</div>
    <div class="times">{{ info.create_time }}</div>
    <div class=" gn-flex-center">
      <video ref="video" controls autoplay v-if="info.video">
        <source :src="info.video" type="video/mp4" />
        <source :src="info.video" type="video/ogg" />
        <source :src="info.video" type="video/webm" />
      </video>
    </div>
    <div v-html="info.content" class="html-text" v-if="info.content && type === 'video'"></div>
    <div class="more">
      <div class="btn" @click="lookMore">查看更多</div>
    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/admin'

export default {
  name: 'video',
  data () {
    return {
      info: {},
      type: '',
      reqParams: {
        page: 1,
        per_page: 6,
        search_data: '', // 关键字搜索
        advisory_type: '1', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      }
    }
  },
  created () {
    console.log(this.$route.query)
    this.reqParams.search_data = this.$route.query.title
    this.reqParams.advisory_type = this.$route.query.advisory_type
    this.type = this.$route.query.type
    this.getInfoList()
  },
  methods: {
    getInfoList () {
      getArticleList(this.reqParams).then((res) => {
        console.log(res)
        this.info = res.list[0]
      })
    },
    lookMore () {
      this.$router.back()
    }
  }
}
</script>

<style lang='scss' scoped>
#video {
  width: 1200px;
  margin: 0 auto;
  margin-top: 68px;

  .title {
    font-size: 48px;
    font-weight: 700;
    color: #333334;
    margin-bottom: 45px;
  }
  .tabs {
    display: flex;
    justify-content: center;
    width: 100%;

    div {
      padding-bottom: 20px;
      box-sizing: border-box;
      font-size: 24px;
      font-family: Source Han Sans CN, Source Han Sans CN-Bold;
      text-align: justifyLeft;
      color: #333334;
      line-height: 51px;
    }
    div:nth-child(1) {
      margin-right: 60px;
    }
    .active {
      font-weight: 700;
      border-bottom: 2px solid #00a0ac;
    }
  }
  .news-title {
    font-size: 30px;
    border-top: 1px solid #ccc;
    padding-top: 50px;
  }
  .times {
    margin-bottom: 50px;
    text-align: center;
  }
  video{
    width: 1166px;
    height: 655px
  }
  .html-text{
    padding-bottom: 30px;
    margin-top: 20px;
  }
  .more{
    margin: 91px 0 81px 0;
    .btn{
      margin: 0 auto;
      width: 142px;
      height: 42px;
      border: 0.5px solid #333333;
      color: #333333;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      line-height: 42px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
/* 小于 768 px (手机端) */
@media all and (max-width: 768px) {
  /* 在这里添加针对小于 768px 的样式，通常用于手机端 */

  #video {
    width: 100% !important;
    margin-top: 44px !important;
    padding: 10px !important;
  }
  .news-title{
    font-size: 20px !important;
    border-top:none !important;
    padding-top:27px !important;
    margin-bottom:20px !important;
  }
  .times{
    margin-bottom:20px !important;
  }
  .html-text{
    margin: 0 !important;
    width: 100% !important;
    ::v-deep img{
      width: 100% !important;
      margin: 10px 0 !important;
    }
    ::v-deep font {
      font-size: 16px;
    }
  }
  #video video{
    width: 100% !important;
    height: 220px !important;
  }
  .more{
    margin:30px 0 20px 0 !important;
  }
}
</style>
